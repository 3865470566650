<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  data() {
    return {
      status: null,
    };
  },
  methods: {
    getConfig() {
      api.get("franchise/config").then((response) => {
        if (response.data.status == "unregistered") {
          this.status = response.data.status;
        }
      });
    },
  },
  mounted() {
    this.getConfig();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Franquia") }}</h4>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body"></div>
    </div>
  </Layout>
</template>
